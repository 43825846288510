import React from 'react'
import { Box } from '@chakra-ui/react'

const PageNoHero = (props) => {
const { children } = props;

  return (
    <Box bg={props.bgColor}>
        {children}
    </Box>
  )
}

export default PageNoHero